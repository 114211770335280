<template>
  <div class="my-team">
    <v-row>
      <v-col>
        <h1 class="text-center">{{ team.name }}</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6">
        <v-card elevation="4" class="stat-card">
          <div class="icon"><v-icon>mdi-hand-back-right-outline</v-icon></div>
          <div class="value">{{ team.classPledge }}</div>
          <div class="label">
            Classes Pledged
          </div>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card elevation="4" class="stat-card">
          <div class="icon star"><v-icon>mdi-star</v-icon></div>
          <div class="value">{{ team.totalClasses }}</div>
          <div class="label">
            Classes Taken
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12">
        <h1 class="text-center mb-0 mt-5">Donation Tracker</h1>
      </v-col>
      <v-col cols="4">
        <v-img v-if="team.charityId === 1" src="../../assets/dyp/casa.png" />
        <v-img v-if="team.charityId === 2" src="../../assets/dyp/communidad.png" />
        <v-img v-if="team.charityId === 3" src="../../assets/dyp/pets.png" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6">
        <v-card height="140" elevation="4" class="stat-card">
          <div class="value">${{ team.donationTotal + pledgeDonationAmount }}</div>
          <div class="label">
            Your Team's Donation
          </div>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card height="140" elevation="4" class="stat-card">
          <div class="value">${{ dypContributionTotal }}</div>
          <div class="label">
            DYP Contributions to {{ team.charity.name }}
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" class="text-center">
        <p v-if="false">Want to donate even more? <a :to="{}">Click here to donate</a></p>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <h1 class="text-center">Our Team</h1>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="6">
        <v-card elevation="4" class="stat-card">
          <h3>Team Captain</h3>
          <h3>{{ teamCaptain[0].firstName }} {{ teamCaptain[0].lastName.charAt(0) }}.</h3>
          <div class="value">{{ teamCaptain[0].totalClasses }}</div>
          <div class="label">
            Classes Taken
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="6" v-for="member in teamMembers" :key="member.id">
        <v-card elevation="4" class="stat-card">
          <h3>{{ member.firstName }} {{ member.lastName.charAt(0) }}.</h3>
          <div class="value">{{ member.totalClasses }}</div>
          <div class="label">
            Classes Taken
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <h1 class="text-center">Team Link</h1>
      </v-col>
      <v-col cols="9" class="pr-0">
        <v-text-field outlined disabled :value="`https://rayoga.com/join-my-team/?team=${teamSlug}`" />
      </v-col>
      <v-col cols="3">
        <v-btn height="56" depressed color="#3E523C" @click="copy" dark x-large><v-icon>mdi-content-copy</v-icon></v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <h1 class="text-center">DYP Pledge</h1>
      </v-col>
      <v-col cols="12">
        <p>Dedicate Your Practice is an annual tradition where Ra Yoga challenges its community to take their regular practice to the next level. Every class you complete brings you closer to your personal goals while strengthening charities around us. During DYP, we’re practicing with purpose — both on the mat and in the world around us. Make every class count in a new way this November and do not forget to rally your team to achieve your goals!</p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'MyTeam',

  computed: {
    team() {
      return this.$store.state.team
    },
    teams() {
      return this.$store.sate.teams
    },
    charityDonationTotal() {
      let total = 0
      const charityTeams = this.teams.filter((team) => team.charityId === this.team.charityId)
      charityTeams.forEach((o) => {
        total = total + o.donationTotal
      })

      return total
    },
    teamCaptain() {
      return this.team.members.filter(o => o.isCaptain)
    },
    teamMembers() {
      return this.team.members.filter(o => !o.isCaptain)
    },
    teamSlug() {
      return this.team.name.toLowerCase().replace(' ', '-').replace('\'', '');
    },
    pledgeDonationAmount() {
      let pledgeDonationAmount = 0
      switch(this.team.classPledge) {
        case 80:
          pledgeDonationAmount = 20
          break
        case 100:
          pledgeDonationAmount = 30
          break
        case 120:
          pledgeDonationAmount = 40
      }

      return pledgeDonationAmount
    },
    dypContributionTotal() {
      if (this.team.charityId === 1) return this.$store.state.casaTeamTotal
      if (this.team.charityId === 2) return this.$store.state.comTeamTotal
      if (this.team.charityId === 3) return this.$store.state.petTeamTotal
      return 0
    }
  },

  methods: {
    getTeam() {
      this.$store.dispatch('getTeam', this.$route.params.id)
    },
    copy() {
      const url = `https://rayoga.com/join-my-team/?team=${this.teamSlug}`
      navigator.clipboard.writeText(url)
    }
  },

  mounted() {
    this.getTeam()
  }
}
</script>

<style lang="scss">

</style>