import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import axios from 'axios'
import router from '../router'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    createPersistedState(),
  ],
  state: {
    session: {},
    challenge: 'OTTSHyNGCKvkGuayW4ncpGGWW2aKVe',
    challengeId: 2,
    baseEccoUrl: process.env.VUE_APP_BASE_ECCO_URL,
    baseUrl: process.env.VUE_APP_BASE_URL,
    state: '39fh03fhj30gh0jfowej0fifjasodin0jhkg2',
    clientId: process.env.VUE_APP_CLIENT_ID,
    redirectUri: process.env.VUE_APP_REDIRECT_URI,
    marianaTekUri: process.env.VUE_APP_MARIANA_TEK_URI,
    marianaBaseUri: process.env.VUE_APP_BASE_MARIANA_URI,
    code: null,
    loginSession: {},
    login: {},
    user: {},
    challengeRecord: null,
    classCount: 0,
    prompt: 0,
    signupError: null,
    locations: [],
    locationsLoading: false,
    ref: null,

    // DYP 2024
    teams: null,
    leaderboardTotalPledges: 0,
    leaderboardDonationTotal: 0,
    myTeam: null,
    team: null,
    casaTeamTotal:0,
    comTeamTotal: 0,
    petTeamTotal: 0,
  },
  mutations: {
    setLoginSession(state, val) {
      state.loginSession = val
    },
    setSession(state, val) {
      state.session = val
    },
    setUser: (state, val) => {
      state.user = val
    },
    setChallenge: (state, val) => {
      state.challenge = val
    },
    setCode: (state, val) => {
      state.code = val
    },
    setChallengeRecord: (state, val) => {
      state.challengeRecord = val
    },
    setClassCount: (state, val) => {
      state.classCount = val
    },
    setLocations: (state, val) => {
      state.locations = val
    },
    setLocationsLoading: (state, val) => {
      state.locationsLoading = val
    },

    // DYP 2024
    setTeams: (state, val) => {
      state.teams = val
    },
    setTeam: (state, val) => {
      state.team = val
    },
    setTotalPledges: (state, val) => {
      state.leaderboardTotalPledges = val
    },
    setDonationTotal: (state, val) => {
      state.leaderboardDonationTotal = val
    },
    setMyTeam: (state, val) => {
      state.myTeam = val
    },
    setCasaTeamTotal: (state, val) => {
      state.casaTeamTotal = val
    },
    setComTeamTotal: (state, val) => {
      state.comTeamTotal = val
    },
    setPetTeamTotal: (state, val) => {
      state.petTeamTotal = val
    }
  },
  actions: {
    authenticate: async ({ commit, dispatch, state }) => {
      await axios.post(`${state.marianaBaseUri}/o/token/?client_id=${state.clientId}&code_verifier=${state.challenge}&code=${state.code}&grant_type=authorization_code&redirect_uri=${state.redirectUri}`)
      .then(async (data) => {
        commit('setSession', data.data)
        await dispatch('getProfile')
        router.push({ name: 'DypLeaderboardRoute' })
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        //router.push({ name: 'Entry' })
        console.log(error.config);
      });
    },
    getProfile: async ({ commit, state }) => {
      return new Promise((resolve, reject) => {
        axios.get(`${state.baseUrl}/me/account`, {
          headers: {
            'Authorization': `Bearer ${state.session.access_token}`
          }
        }).then(async (res) => {
          commit('setUser', res.data)
          //dispatch('checkForChallengeSignup')
          resolve('user set')
        }).catch((err) => {
          console.log(err.response)
          reject('error getting profile')
        })
      })
    },
    createChallengeSignup: async ({ commit, state }, data) => {
      return new Promise((resolve, reject) => {
        axios.post(`${state.baseEccoUrl}/challenge/signup`, data)
          .then(res => {
            commit('setChallengeRecord', res.data)
            resolve('challenge created')
          })
          .catch(err => {
            console.log(err)
            reject('error creating challenge')
          })
      })
    },

    checkForChallengeSignup: async ({ commit, state }) => {
      return new Promise((resolve, reject) => {
        axios.get(`${state.baseEccoUrl}/challenge/signup?customerId=${state.user.id}&challengeId=${state.challengeId}`)
          .then((res) => {
            if (res.data.total > 0) {
              commit('setChallengeRecord', res.data.data[0])
              router.push('/challenge')
              resolve(true)
            }
          })
          .catch(err => {
            console.log(err)
            reject('error retrieving challenge')
          })
      })
    },
    getTotalClasses: async ({ commit, state }, customerId) => {
      return new Promise((resolve, reject) => {
        axios.get(`${state.baseEccoUrl}/mariana/reservation?include=class_session&user_or_booked_on_behalf_of_user=${customerId}&class_session_min_date=2024-03-01&class_session_max_date=2024-03-31&status=check%20in&limit=100&reserved_for_guest=false`)
          .then(res => {
            commit('setClassCount', res.data.total)
            resolve('classes retrieved')
          })
          .catch(err => {
            console.log(err)
            reject('class retrieval failed')
          })
      })
    },

    // DYP 2024
    getTeams: async ({ commit, state }) => {
      return new Promise((resolve, reject) => {
        axios.get(`${state.baseEccoUrl}/dyp/team`, {
          params: {
            $limit: 1000,
          }
        })
          .then((res) => {
            let filteredTeams = res.data.data.filter(o => o.members.length > 0)

            let totalPledges = 0
            let donationTotal = 0
            filteredTeams.forEach(o => {
              totalPledges = totalPledges + o.classPledge

              switch(o.classPledge) {
                case 80:
                  donationTotal = donationTotal + 20
                  break
                case 100:
                  donationTotal = donationTotal + 30
                  break
                case 120:
                  donationTotal = donationTotal + 40
              }
            })

            filteredTeams = filteredTeams.map(o => {
              let pledgeDonationAmount = 0
              switch(o.classPledge) {
                case 80:
                  pledgeDonationAmount = 20
                  break
                case 100:
                  pledgeDonationAmount = 30
                  break
                case 120:
                  pledgeDonationAmount = 40
              }
              return {
                ...o,
                pledgeDonationAmount,
              }
            })

            commit('setTeams', filteredTeams)
            commit('setTotalPledges', totalPledges)
            commit('setDonationTotal', donationTotal)
            
            const casaTeams = filteredTeams.filter(o => o.charityId === 1)
            const comTeams = filteredTeams.filter(o => o.charityId === 2)
            const petsTeams = filteredTeams.filter(o => o.charityId === 3)

            let casaTeamTotal = 0
            let comTeamTotal = 0
            let petsTeamTotal = 0

            casaTeams.forEach(o => {
              casaTeamTotal = casaTeamTotal + o.pledgeDonationAmount + o.donationTotal
            })
            commit('setCasaTeamTotal', casaTeamTotal)

            comTeams.forEach(o => {
              comTeamTotal = comTeamTotal + o.pledgeDonationAmount + o.donationTotal
            })
            commit('setComTeamTotal', comTeamTotal)

            petsTeams.forEach(o => {
              petsTeamTotal = petsTeamTotal + o.pledgeDonationAmount + o.donationTotal
            })
            commit('setPetTeamTotal', petsTeamTotal)

            resolve('teams retrieved')
          })
          .catch((err) => {
            console.log(err)
            reject('teams retrieval failed')
          })
      })
    },
    getTeam: async ({ commit, state }, id) => {
      return new Promise((resolve, reject) => {
        axios.get(`${state.baseEccoUrl}/dyp/team/${id}`)
          .then((res) => {
            let totalClasses = 0
            res.data.members.forEach(o => {
              totalClasses = totalClasses + o.totalClasses
            })

            commit('setTeam', {
              ...res.data,
              totalClasses
            })

            resolve('team retrieved')
          })
          .catch((err) => {
            console.log(err)
            reject('team retrieval failed')
          })
      })
    },
    getMyTeam: async ({ commit, state }) => {
      return new Promise((resolve, reject) => {
        if (state.user.id) {
          axios.get(`${state.baseEccoUrl}/dyp/member`, {
            params: {
              marianaTekId: state.user.id,
            }
          })
          .then((res) => {
            console.log(res.data)
            if (res.data.total > 0) {
              commit('setMyTeam', res.data.data[0].team)
              resolve('got my team')
            } else {
              commit('setMyTeam', null)
              resolve('no team found')
            }
          })
          .catch(err => {
            console.log(err)
            reject(err)
          })
        } else {
          reject('No session found')
        }
      })
    }
  },

  getters: {
    isAuthenticated(state) {
      return (state.session.access_token && state.session.access_token.length > 0)
    },
    hasSignedUp(state) {
      return (state.challengeRecord && state.challengeRecord.id)
    }
  },

  modules: {
  }
})
