import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Entry from '../views/Entry.vue'
import OauthCallbackView from '../views/OauthCallbackView.vue'
import DypMain from '../views/Dyp.vue'
import MyTeam from '@/components/dyp/MyTeam.vue'
import Leaderboard from '@/components/dyp/Leaderboard.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Entry',
    component: Entry
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/challenge',
    name: 'Challenge',
    component: () => import('../views/Dashboard.vue')
  },
  {
    path: '/oauth/callback',
    name: 'OauthCallbackRoute',
    component: OauthCallbackView,
    meta: { requiresAuth: false }
  },
  {
    path: '/dyp',
    name: 'DypMainRoute',
    component: DypMain,
    children: [
      {
        path: 'leaderboard',
        name: 'DypLeaderboardRoute',
        component: Leaderboard,
      },
      {
        path: 'my-team/:id',
        name: 'DypMyTeamRoute',
        component: MyTeam,
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
