<template>
  <div class="leaderboard">
    <v-row>
      <v-col>
        <h1 class="text-center">Our Community Impact</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-card elevation="4" class="stat-card">
          <div class="icon"><v-icon>mdi-hand-back-right-outline</v-icon></div>
          <div class="value">{{ leaderboardTotalPledges }}</div>
          <div class="label">
            Total Classes Pledged
          </div>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card elevation="4" class="stat-card">
          <div class="icon heart"><v-icon>mdi-heart</v-icon></div>
          <div class="value">${{ leaderboardDonationTotal }}</div>
          <div class="label">
            Pledged To Charity
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="text-center">
        <h4>TO</h4>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="3">
        <v-img src="../../assets/dyp/pets.png" />
      </v-col>
      <v-col cols="3">
        <v-img src="../../assets/dyp/communidad.png" />
      </v-col>
      <v-col cols="3">
        <v-img src="../../assets/dyp/casa.png" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="text-center">
        <h2>All Teams</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="teams-list" v-if="teams">
          <div class="team-item" v-for="team in orderedTeams" :key="team.id">
            <v-row align="center">
              <v-col cols="8">
                <h4 class="team-name mb-0">{{team.name}}</h4>
                <v-row align="center">
                  <v-col cols="4" class="pr-0">
                    <h4 class="donation">${{ team.donationTotal + team.pledgeDonationAmount }} <small>to</small></h4>
                  </v-col>
                  <v-col cols="3" class="pl-0">
                    <v-img v-if="team.charityId === 1" src="../../assets/dyp/casa.png" />
                    <v-img v-if="team.charityId === 2" src="../../assets/dyp/communidad.png" />
                    <v-img v-if="team.charityId === 3" src="../../assets/dyp/pets.png" />
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="4">
                <div class="class-pledge-container">
                  <div class="class-pledge-count">{{ team.classPledge }}</div>
                  <div class="class-count">{{ team.teamClassTotal }}</div>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col>
        <v-btn color="#CB6419" dark depressed x-large block @click.prevent="refresh">Refresh Teams</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { orderBy } from 'lodash'

export default {
  name: 'Leaderboard',

  data() {
    return {

    }
  },

  computed: {
    user() {
      return this.$store.state.user
    },
    teams() {
      return this.$store.state.teams
    },
    leaderboardTotalPledges() {
      return this.$store.state.leaderboardTotalPledges
    },
    leaderboardDonationTotal() {
      return this.$store.state.leaderboardDonationTotal
    },
    orderedTeams() {
      let teams = this.teams

      teams = teams.map(t => {
        return {
          ...t,
          teamClassTotal: this.calculateClassTotal(t)
        }
      })

      teams = orderBy(teams, ['teamClassTotal'], ['desc'])

      return teams
    }
  },

  methods: {
    async getTeams() {
      await this.$store.dispatch('getTeams')
      console.log('got teams')
    },
    calculateClassTotal(team) {
      let classTotal = 0
      team.members.forEach((o) => {
        classTotal = classTotal + o.totalClasses
      })

      return classTotal
    },
    async refresh() {
      console.log('getting the stuff')
      if (this.user.id) await this.$store.dispatch('getMyTeam')
      await this.getTeams()
      console.log('got the stuff')
      window.scrollTo(0,0)
    }
  },

  async beforeMount() {
    await this.getTeams()
  }
}
</script>

<style lang="scss">
.v-card.stat-card {
  text-align: center;
  padding: 15px;
  border-radius: 13px;

  h3 {
    color: #53745C;
  }

  .icon {
    .v-icon {
      color: #000000;
      font-size: 50px;
    }
  }

  .icon.heart {
    .v-icon {
      color: #a80101;
      font-size: 50px;
    }
  }

  .icon.star {
    .v-icon {
      color: #FCE4B0;
      font-size: 50px;
    }
  }

  .value {
    font-size: 48px;
    font-weight: 500;
  }

  .label {
    font-size: 12px;
  }
}


.teams-list {
  width: 100%;
  padding: 15px 15px;
  border-radius: 8px;
  background-color: #C5C5C5;

  .team-item {
    width: 100%;
    padding: 15px 20px;
    border-radius: 8px;
    background-color: #FFFFFF;
    margin-bottom: 10px;
    box-shadow: 1px 1px 6px rgba(0,0,0,0.4);

    .team-name,
    .donation {
      font-size: 20px;
    }

    .class-pledge-container {
      position: relative;
      float: right;
      width: 52px;
      height: 52px;
      border: 1px solid #3E523C;
      border-radius: 4px;
      background-color: #F1F1F1;

      .class-pledge-count {
        position: absolute;
        top: -8px;
        left: calc(50% - 15px);
        width: 30px;
        font-size: 11px;
        text-align: center;
        color: #3E523C;
        background: rgb(241,241,241);
        background: linear-gradient(0deg, rgba(241,241,241,1) 58%, rgba(255,255,255,1) 42%);
      }

      .class-count {
        width: 100%;
        text-align: center;
        font-size: 24px;
        padding-top: 7px;
        color: #3E523C;
      }
    }
  }
}

</style>