<template>
  <div class="oauth">

  </div>
</template>

<script>
import { isEmpty } from 'lodash'

export default {
  name: 'Entry',

  computed: {
    routeName() {
      return this.$route.name
    },
    marianaTekLoginHref() {
      return `${this.$store.state.marianaTekUri}?response_type=code&client_id=${this.clientId}&redirect_uri=${this.redirectUri}&state=${this.state}&code_challenge=${this.challenge}`
    },
    clientId() {
      return this.$store.state.clientId
    },
    state() {
      return this.$store.state.state
    },
    challenge() {
      return this.$store.state.challenge
    },
    redirectUri() {
      return this.$store.state.redirectUri
    },
    session() {
      return this.$store.state.session
    }
  },

  methods: {
    goTo(link) {
      window.open(link, '_blank');
    },
    dec2hex (dec) {
      return dec.toString(16)
    },
    generateId (len) {
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < len) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
      }
      return result;
    },
  },

  async mounted() {
    //await localStorage.clear()
    //await localStorage.removeItem('vuex')

    this.$store.state.session = {}
    this.$store.state.challengeRecord = null

    this.$store.state.code = null
    this.$store.state.challenge = this.generateId(30)
    if (isEmpty(this.session)) {
      window.location.href = this.marianaTekLoginHref
    } else {
      this.$router.push({ name: 'DypLeaderboardRoute' })
    }
  }
}
</script>