<template>
  <v-app id="app">
    <v-main id="main">
      <v-container class="main-body pa-8 pt-15">
        <router-view/>
      </v-container>
    </v-main>
    <v-bottom-navigation color="#f5f5f5" fixed height="100">
      <v-container class="pt-0">
        <v-row>
          <v-col cols="12" class="pb-0">
            <h5><small>Dedicate Your Practice 2024</small></h5>
          </v-col>
          <v-col cols="5">
            <v-img src="@/assets/dyp/FTF-Horizontal.png" />
          </v-col>
        </v-row>
      </v-container>
    </v-bottom-navigation>
  </v-app>
</template>

<script>
export default {
  name: 'App',

  computed: {
    routeName() {
      return this.$route.name
    },
    marianaTekLoginHref() {
      return `${this.$store.state.marianaTekUri}?response_type=code&client_id=${this.clientId}&redirect_uri=${this.redirectUri}&state=${this.state}&code_challenge=${this.challenge}`
    },
    clientId() {
      return this.$store.state.clientId
    },
    state() {
      return this.$store.state.state
    },
    challenge() {
      return this.$store.state.challenge
    },
    redirectUri() {
      return this.$store.state.redirectUri
    },
    session() {
      return this.$store.state.session
    }
  },

  methods: {
    goTo(link) {
      window.open(link, '_blank');
    },
    dec2hex (dec) {
      return dec.toString(16).padStart(2, "0")
    },
    async generateId (len) {
      var arr = new Uint8Array((len || 40) / 2)
      window.crypto.getRandomValues(arr)
      return Array.from(arr, this.dec2hex).join('')
    },
  },

  async beforeMount() {
    this.challenge = await this.generateId(40)
    this.$store.commit('setChallenge', this.challenge)
  },
  mounted() {
  }
}
</script>

<style lang="scss">
.montserrat {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

body .theme--light.v-application {
  font-family: 'Montserrat', sans-serif;
  color: #422115;
  font-weight: 500;

  h1,h2,h3,h4,h5,h6 {
    font-weight: 800;
  }
}

#app {
  background-color: #ffffff;
}

.main-body {
  min-height: 100vh;
  max-width: 480px;
  background-color: #F5F5F5;
  box-shadow: 1px 0px 24px rgba(0,0,0,0.3);
}

.v-main__wrap {
  background-color: #ede4db;
}

@media (min-width: 1000px) {
  #app {
    max-width: calc(600 - 30px);
    margin-left: calc((100vw - 600) / 2);
  }
}

.theme--light.v-bottom-navigation,
.theme--light.v-btn.v-btn--has-bg {
  background-color: #3f523d;
}

.theme--light.v-bottom-navigation {
  top: 0;
  height: 78px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.v-item-group.v-bottom-navigation span.v-btn__content {
  background-color: rgba(255,255,255,0.2);
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 10px;
}

.theme--light.v-bottom-navigation .v-btn:not(.v-btn--active) {
  color: rgba(255,255,255,0.8) !important;
}
</style>
