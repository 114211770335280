<template>
  <v-container>
    <v-row class="justify-center">
      <v-col cols="4" class="text-center mt-8">
        <v-progress-circular
          indeterminate
          color="green"
        ></v-progress-circular>
      </v-col>
    </v-row>
    <v-row justify="center" v-if="showRetry">
      <v-col class="text-center">
        <v-btn color="#CB6419" dark depressed x-large @click="retry">I'm Stuck! Retry!</v-btn>
      </v-col>
    </v-row>
    <v-row justify="center" v-if="showRetry">
      <v-col class="text-center">
        <p>If you've tried the retry button and are still stuck here please try uninstalling the Ra Yoga app and reinstalling it.</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'OAuthCallbackView',

  components: {
  },

  data() {
    return {
      authorizationCode: null,
      clientId: 'xrT4xmhLsDeobT8kvxwlkwojzbLCRsIE8XjBUEwX',
      showRetry: false,
    }
  },

  computed: {
    challenge() {
      return this.$store.state.challenge
    }
  },

  methods: {
    async retry() {
      await localStorage.clear()
      await localStorage.removeItem('vuex')
      window.location = 'https://mobile-tab.rayoga.com'
    }
  },

  async mounted() {
    console.log('MOUNTED')
    console.log(this.$route.query.code)
    this.$store.state.code = this.$route.query.code
    await this.$store.dispatch('authenticate')

    setTimeout(() => {
      this.showRetry = true
    }, 2500)
  }
}
</script>

<style lang="sass" scoped>
</style>