<template>
  <div class="dyp-main" style="padding-top: 60px">
    <v-row v-if="false" justify="center">
      <v-col class="text-center">
        <h2>Challenge Starts November 1st!</h2>
      </v-col>
    </v-row>
    <v-row v-if="false" justify="center">
      <v-col cols="10">
        <div class="join-dyp">
          <h1 class="mb-4">Join the fun!</h1>
          <v-btn color="#CB6419" rounded x-large depressed href="https://rayoga.com/dyp2024" target="_BLANK">Sign Up Today</v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row class="mb-4">
      <v-col cols="12">
        <v-btn-toggle class="dyp-nav" color="#CB6419">
          <v-btn block exact :to="{ name: 'DypLeaderboardRoute' }">All Teams</v-btn>
          <v-btn :disabled="!myTeamId" block exact :to="{ name: 'DypMyTeamRoute', params: { id: myTeamId } }">My Team</v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'DypMain',

  data() {
    return {
    }
  },

  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated
    },
    user() {
      return this.$store.state.user
    },
    myTeamId() {
      return this.$store.state.myTeam?.id
    }
  },

  methods: {
    dec2hex (dec) {
      return dec.toString(16).padStart(2, "0")
    },
    generateId (len) {
      var arr = new Uint8Array((len || 40) / 2)
      window.crypto.getRandomValues(arr)
      return Array.from(arr, this.dec2hex).join('')
    },
    copyToClipboard() {
      navigator.clipboard.writeText(this.referenceUrl)
    },
    async getMyTeam() {
      await this.$store.dispatch('getMyTeam')
    }
  },

  async mounted() {
    //if (!this.isAuthenticated) this.$router.push({ name: 'Home' })
    await this.getMyTeam()
  }
}
</script>

<style lang="scss">
.dyp-main {
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 400;
  font-style: normal;

  .join-dyp {
    width: 100%;
    text-align: center;
    background-color: #3F523E;
    border-radius: 13px;
    padding: 25px 15px;

    h1,
    .v-btn .v-btn__content {
      color: #ffffff;
    }
  }

  .theme--light.v-btn.v-btn--active {
    background-color: #CB6419;
    color: #FFFFFF;
  }

  .theme--light.v-btn {
    background: #ffffff;
    border-color: #CB6419;
    color: #CB6419;
  }

  .theme--light.v-btn-toggle .v-btn.v-btn {
    border-color: #CB6419;
  }

  h1,h2,h3,h4,h5 {
    font-family: "brandon-grotesque", sans-serif;
    font-weight: 700 !important;
    font-style: normal;
  }
}

.dyp-nav {
  width: 50%;
  .v-btn--block {

  }
}
</style>